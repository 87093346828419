import { mapActions, mapGetters } from 'vuex'
import colors from '../../helpers/package-colors.js'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  components: {
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    headers () {
      return [
        {
          text: 'Questions',
          value: 'text'
        },
        {
          text: 'Incorrect',
          value: 'count_incorrect'
        },
        {
          text: 'Correct',
          value: 'count_correct'
        }
      ]
    },
    ...mapGetters({
      courseQuestions: 'statistics/homeWorkData',
      loading: 'statistics/loading'
    }),
    questionsColors () {
      return colors
    },
    reportFn () {
      return this.createReportFn({
        url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/modules/${this.$route.params.moduleId}/home-works/${this.$route.params.materialId}`,
        method: 'get'
      })
    }
  },

  created () {
    this.fetchQuestions({
      courseId: this.$route.params.courseId,
      moduleId: this.$route.params.moduleId,
      materialId: this.$route.params.materialId,
      include: 'questions.answers'
    })
  },
  methods: {
    ...mapActions({
      fetchQuestions: 'statistics/GET_STATISTICS_HOME_WORK_DETAILS'
    })
  }
}
